<template>
  <article class="article" ref="article"></article>
</template>

<script>
import { posts } from "../../contants/post";

/** 给表格填充容器 */
function fillTableContainer(
  htmlString,
  containerClassName = "table-container"
) {
  var newHtml = htmlString.replace(
    /<table/g,
    `<div class="${containerClassName}"><table`
  );

  return newHtml.replace(/<\/table>/g, "</table></div>");
}

export default {
  mounted() {
    const { docName } = this.$route.params;
    const post = posts.find((v) => v.path === docName);

    fetch(
      "https://www.huabanche.club/privacy_ad/admin/privacy_info_get?id=" +
        post.id
    )
      .then((res) => res.json())
      .then((res) => {
        debugger;
        this.$refs.article.innerHTML = fillTableContainer(res.data.content);
        document.title = res.data.title;
      });
  },
};
</script>
<style lang="less">
.article {
  width: 90%;
  max-width: 800pt;
  margin: 50pt auto;
  font-size: 12pt;
  font-family: "Helvetica Neue", Helvetica, Arial, "Microsoft Yahei", sans-serif;

  table {
    border-collapse: collapse;
  }

  table,
  th,
  td {
    border: 1px solid #ddd;
    padding: 1ex;
  }

  th {
    white-space: nowrap;
    background-color: #eee;
  }

  td {
    word-break: break-word;
    min-width: 100px;
  }

  .table-container {
    width: 100%;
    max-height: 80vh;
    overflow: auto;
    box-shadow: 0 2px 5px 0 #ddd;
  }

  hr {
    border: none;
    border-top: 1px solid #ddd;
  }
}
</style>
